export const ARC_POSITION = {
  KISS: {
    positions: [45, 138, 188, 233, 310],
    scale: 1,
    hybridScale: 1.5,
  },
  HUG: {
    positions: [188, 233, 290, 316, 354],
    scale: 1.5,
    hybridScale: 2.5,
  },
  HIGH_FIVE: {
    positions: [188, 233, 255, 290, 316],
    scale: 1.5,
    hybridScale: 2.5,
  },
  WAVE: {
    positions: [233, 255, 290, 316],
    scale: 1.5,
    hybridScale: 2.5,
  },
  STRANGER: {
    positions: [233, 255, 290, 316],
    scale: 1.5,
    hybridScale: 2.5,
  },
};
